import axios from "axios";
import { useStore } from "../App";
import { Navigate } from "react-router-dom";
import { useQueryClient } from "react-query";
import BASE_URL_WITH_PROTOCOL from "../utils/dynamicUrl";

if (process.env.NODE_ENV == "production") {
}

var authApi = axios.create({
  baseURL: `${BASE_URL_WITH_PROTOCOL}` + "/api/",
});

export const sendLogin = async (credentials) => {
  return await authApi.post("accounts/login", credentials);
};

export const sendAdminLogin = async (credentials) => {
  return await authApi.post("accounts/admin-login", credentials);
};

export const sendAdminOtpCode = async (credentials) => {
  return await authApi.post("accounts/validate-otp", credentials);
};

export const sendSignup = async (credentials) => {
  const userType = credentials.userType;
  delete credentials.userType

  if (userType == "employer") {
    return await authApi.post("company/signup", credentials);
  } else if (userType == "job-seeker" || userType == "campus-ambassador") {
    return await authApi.post("candidate/signup", credentials);
  } else if (userType == "trainer") {
    return await authApi.post("trainings/trainer-signup", {
      ...credentials,
      city: "",
    });
  }
};

export const logOut = () => {
  useStore.setState({ userStatus: false });
  useStore.setState({ userType: null });
  useStore.setState({ accessToken: null });
  useStore.setState({ refreshToken: null });
  useStore.setState({ name: null });
  useStore.setState({ phone: null });
  useStore.setState({ email: null });

  navigateUser();
};

const navigateUser = () => {
  <Navigate to="/" />;
};

export default authApi;
